body,
html,
#root,
.App {
  width: 100%;
  height: 100%;
}

iframe {
  border: none;
}
